import clsx from "clsx"
import * as React from "react"
import { Container } from "react-bootstrap"
import BackgroundGradients from "../../../components/BackgroundGradients"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import * as styles from "./Error404Page.module.scss"
import confusedBirdGifPath from "./confused-bird.gif"

const Error404Page = () => {
  return (
    <Layout logoColor="light" menuColor="light">
      <Seo title="Page not found" />

      <div className={styles.pageContent}>
        <Container fluid="xxl">
          <div className={styles.error}>
            <h1 className={clsx("display-1", styles.errorCode)}>404</h1>
            <h2 className="h4">Page not found</h2>
            <p className={clsx("mb-5", styles.errorMessage)}>
              Looks like you’ve found the secret answer to perfect parenting.
              Unfortunately we don’t know what that is so best we can offer is
              this gif in the meantime.
            </p>
            <img src={confusedBirdGifPath} alt="Confused bird knows nothing" />
          </div>
        </Container>
      </div>

      <BackgroundGradients
        intensity="high"
        className={styles.backgroundGradients}
      >
        <div className={styles.green1}></div>
        <div className={styles.yellow1}></div>
      </BackgroundGradients>
    </Layout>
  )
}

export default Error404Page
